<template>
  <div class="register_box clr">
    <div class="register_img fl">
      <div id="wx_reg"></div>
    </div>
    <div class="register_text fr">
      <div class="text">
        特别申明：使用微信扫码完成注册校验，同时方便注册用户通过微信扫码的方式进行登录。在此仅获取用户的微信昵称和头像，不涉及用户其他隐私。
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    let that = this;
    setTimeout(() => {
      that.$api.weixinconfig_reg().then((res) => {
        new WxLogin({
          self_redirect: true,
          id: "wx_reg",
          appid: res.data.appid,
          scope: res.data.scope,
          redirect_uri: res.data.redirect_uri,
          state: "",
          style: "black",
          href: `https://www.shihaishibiao.com/static/css/code.css`,
        });
      });
    }, 0);
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.register_box {
  .register_img {
    width: 112px;
    font-size: 0;
    height: 112px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .register_text {
    width: 210px;
    height: 112px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    /* 垂直居中 */
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -ms-flex-align: center; /* IE 10 */
    -webkit-align-items: center;
    -moz-align-items: center;
    .text {
      font-size: 12px;
      text-align: left;
    }
    a {
      color: rgb(66, 145, 227);
    }
  }
}
</style>
