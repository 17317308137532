<template>
  <div class>
    <div class="code_img">
      <div id="wx_reg" class="img"></div>
    </div>
    <div class="code_hint">
      打开微信，扫一扫登录
      <br />登录安全又快捷
    </div>
  </div>
</template>
<script>
import Bus from "@/utils/bus";
export default {
  data() {
    return {
      poll_status: null,
      scanCode: "",
      // status:false
    };
  },
  mounted() {
    setTimeout(async () => {
      const res = await this.$api.weixinconfig();
      new WxLogin({
        self_redirect: true,
        id: "wx_reg",
        appid: res.data.appid,
        scope: res.data.scope,
        redirect_uri: res.data.redirect_uri,
        state: res.data.state,
        style: "black",
        href: "data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZSB7DQogIHdpZHRoOiAxNTBweDsNCiAgbWFyZ2luOiAwOw0KfQ0KDQouaW1wb3dlckJveCB7DQogIGxpbmUtaGVpZ2h0OiAwOw0KICBmb250LXNpemU6IDA7DQogIHdpZHRoOiBhdXRvOw0KICB0ZXh0LWFsaWduOiBjZW50ZXI7DQogIHdpZHRoOiAxMDAlOw0KICANCn0NCg0KYm9keSB7DQogIG1hcmdpbjogMDsNCn0NCg0KLmltcG93ZXJCb3ggLnRpdGxlIHsNCiAgZGlzcGxheTogbm9uZTsNCn0NCg0KLmltcG93ZXJCb3ggLmluZm8gew0KICB3aWR0aDogMTUwcHg7DQogIGRpc3BsYXk6IG5vbmU7DQp9DQoNCi5zdGF0dXNfaWNvbiB7DQogIGRpc3BsYXk6IG5vbmUNCn0NCg0KLmltcG93ZXJCb3ggLnN0YXR1cyB7DQogIHRleHQtYWxpZ246IGNlbnRlcjsNCn0NCg==",
      });
      this.scanCode = res.data.state;
      this.poll();
    }, 0);
  },
  created() {
    Bus.$on("off_poll", (e) => {
      clearTimeout(this.poll_status);
      this.poll_status = null;
    });
  },
  methods: {
    async poll() {
      let that = this;
      const res = await that.$api.scanCode({ scanCode: this.scanCode });
      if (res.errcode === 0) {
        clearTimeout(that.poll_status);
        if (res.data.mobileActivated) {
          this.$message.success("登录成功");
          this.$router.replace("/index");
        } else {
          that.$message("请您绑定手机号完成注册~");
          that.$emit("code_tell");
        }
      } else {
        that.poll_status = setTimeout(() => {
          that.poll();
        }, 1000);
      }
    },
    off_poll() {
      clearTimeout(this.poll_status);
      this.poll_status = null;
    },
  },
  destroyed() {
    clearTimeout(this.poll_status);
    this.poll_status = null;
  },
};
</script>

<style lang="scss" scoped>
.code_img {
  color: #000;
  .img {
    // width: 164px;
    height: 164px;
    width: 100%;
  }
}
.code_hint {
  text-align: center;
  font-size: 13px;
}
iframe {
  width: auto;
  height: auto;
}
</style>
