<template>
  <div class="card-wrap">
    <div class="card-center" style="color: #fff">
      <div class="item">
        <li>
          <span class="text"
            >注册享首单<a
              class="yellow"
              target="_blank"
              style="word-spacing: -5px"
              href="https://www.shihaishibiao.com/docs/price.html#%E4%BC%98%E6%83%A0%E6%94%BF%E7%AD%96"
            >
              <svg
                viewBox="0 0 1024 1024"
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="auto"
                style="vertical-align: text-bottom; margin-left: 2px"
              >
                <path
                  d="M483.4 72.9c2.1-6.4-5.7-11.4-10.6-6.9-62.5 56.9-259.2 252.4-299.8 492.7-27.2 161 59.8 320.6 211.6 380.8 39.9 15.8 83.2 24.5 128.5 24.5 22.6 0 44.8-2.2 66.3-6.3 192-37 311.8-229.6 264-419.2-17.7-70.3-47.7-137.2-88.7-197.4-9.7-14.3-31.5-11.3-37.1 5.1-24.9 72.9-70.4 122.1-122.8 122.1-79 0-143-111.3-143-248.1-0.8-42.5 19.8-111.5 31.6-147.3z"
                  fill="#CE3604"
                  p-id="2034"
                ></path>
                <path
                  d="M479.3 921.8c22.6 0 44.8-2.2 66.3-6.3 192-37 311.8-229.6 264-419.2-14.5-57.5-37.2-112.6-67.4-163.7-9.8-2.3-20.9 2.6-24.7 13.6-24.9 72.9-70.4 122.1-122.8 122.1-79 0-143-111.3-143-248.1-0.8-42.4 19.8-111.4 31.6-147.3 2.1-6.4-5.7-11.4-10.6-6.9-62.4 56.9-259.1 252.4-299.7 492.7-15.5 91.6 6.1 182.8 55.5 255.5 33.4 35.5 74.6 64.2 122.3 83.1 39.8 15.8 83.2 24.5 128.5 24.5z"
                  fill="#F24500"
                  p-id="2035"
                ></path>
                <path
                  d="M479.3 921.8c22.6 0 44.8-2.2 66.3-6.3 192-37 311.8-229.6 264-419.2-14.5-57.5-37.2-112.6-67.4-163.7-9.8-2.3-20.9 2.6-24.7 13.6-24.9 72.9-70.4 122.1-122.8 122.1-79 0-143-111.3-143-248.1-0.8-42.4 19.8-111.4 31.6-147.3 2.1-6.4-5.7-11.4-10.6-6.9-62.4 56.9-259.1 252.4-299.7 492.7-15.5 91.6 6.1 182.8 55.5 255.5 33.4 35.5 74.6 64.2 122.3 83.1 39.8 15.8 83.2 24.5 128.5 24.5z"
                  fill="#F25A25"
                  p-id="2036"
                ></path>
                <path
                  d="M479.3 921.8c22.6 0 44.8-2.2 66.3-6.3 192-37 311.8-229.6 264-419.2-14.5-57.5-37.2-112.6-67.4-163.7-9.8-2.3-20.9 2.6-24.7 13.6-24.9 72.9-70.4 122.1-122.8 122.1-79 0-143-111.3-143-248.1-0.3-16.7 2.8-37.6 7.3-59-82.9 84.4-223.1 252.4-256.6 451.1-11.7 69.5-2.2 138.6 24.7 199.7 0.5 0.7 1 1.5 1.5 2.3 33.4 35.5 74.6 64.2 122.3 83.1 39.7 15.7 83.1 24.4 128.4 24.4z"
                  fill="#F24500"
                  p-id="2037"
                ></path>
                <path
                  d="M367.2 573.2c8.1 0 14.6 6.5 14.6 14.6v25.6l-1.2 21c9-8.4 20-16.4 35.2-16.4 24.2 0 34.6 16.8 34.6 45v55.2c0 8.1-6.5 14.6-14.6 14.6h-0.2c-8.1 0-14.6-6.5-14.6-14.6v-51.6c0-17.2-4.8-23.4-15.8-23.4-9.2 0-15 4.4-23.4 12.6v62.4c0 8.1-6.5 14.6-14.6 14.6s-14.6-6.5-14.6-14.6V587.8c0.1-8.1 6.6-14.6 14.6-14.6zM473.7 677c0-37.4 25.8-59 53.8-59 28 0 53.8 21.6 53.8 59 0 37-25.8 58.6-53.8 58.6-28 0-53.8-21.6-53.8-58.6z m77.6 0c0-21.2-8.6-35-23.8-35s-23.8 13.8-23.8 35c0 21 8.6 34.6 23.8 34.6s23.8-13.6 23.8-34.6zM604.5 693.6V644H600c-6.3 0-11.3-5.1-11.3-11.3 0-5.9 4.5-10.8 10.4-11.3l6.8-0.5 2.3-19.9c0.6-5.7 5.5-10.1 11.3-10.1h3.1c6.3 0 11.3 5.1 11.3 11.3v18.7h16.3c6.3 0 11.3 5.1 11.3 11.3v0.5c0 6.3-5.1 11.3-11.3 11.3h-16.3v49.6c0 12.8 5.2 18.8 15.2 18.8 3.6 0 8-1.2 11-2.4l2.5 11.3c1.3 5.9-2.4 11.8-8.3 13-3.9 0.8-8.2 1.3-13 1.3-26.6 0-36.8-16.8-36.8-42z"
                  fill="#FFFFFF"
                  p-id="2038"
                ></path>
              </svg>
              <span class="text-price" style="font-size: 19px">9.9</span>
            </a>
          </span>
        </li>
      </div>
      <div class="item">
        <li>
          <span class="text">全场活动</span>
          <span class="text">
            <el-tooltip content placement="top">
              <span slot="content"
                ><span style="text-decoration: line-through"
                  >原价 <span class="text-price">300</span> 元</span
                >
                ，现价 <span class="text-price">150</span> 元</span
              >
              <a class="yellow">半价</a> </el-tooltip
            >优惠
          </span>
        </li>
      </div>
      <div class="item">
        <li>
          <span class="text">量大无忧</span>
          <a
            class="text yellow"
            target="_blank"
            href="https://www.shihaishibiao.com/docs/price.html#%E4%BC%98%E6%83%A0%E6%94%BF%E7%AD%96"
            >多充多送</a
          >
        </li>
      </div>
      <div class="btn">
        <a role="button" @click="() => $emit('change')">注册新账号</a>
      </div>
      <div class="top-icon">
        <img src="../assets/gifts.svg" alt srcset />
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped>
.card-wrap {
  display: inline-block;
  .card-center {
    position: relative;
    overflow: hidden;
    color: #fff;
    width: 265px;
    padding: 35px 25px;
    background-color: rgba(0, 0, 0, 0.55);
    font-size: 17px;
    line-height: 1.7;
    box-sizing: border-box;
    border-radius: 3px;
    box-shadow: 2px -2px 12px rgba(242, 200, 17, 0.6);
    .item {
      text-align: center;
      .text {
        margin-right: 10px;
      }
    }
    .btn {
      padding-top: 24px;
      a {
        min-width: 140px;
        padding: 0 20px;
        background-color: #f2c811;
        color: #000;
        font-size: 14px;
        line-height: 35px;
        text-align: center;
        display: inline-block;
        cursor: pointer;
        outline: 0;
        box-sizing: border-box;
        width: 100%;
        border-radius: 0;
        height: 40px;
        line-height: 40px;
        font-size: 16px;
      }
    }
  }
  .top-icon {
    position: absolute;
    left: -20px;
    top: -6px;
    transform: rotate(-45deg);
    padding-top: 10px;
    background: #f2c811;
    width: 60px;
    text-align: center;
    line-height: 0;
    img {
      width: 23px;
    }
  }
}
.yellow {
  color: #f2c811;
}
</style>