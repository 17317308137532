<template>
  <div class="login_wrap">
    <div class="login_pop">
      <div class="title">{{ title }}</div>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
        <!-- 扫码 -->
        <div class="code_list" v-if="state === 0">
          <Code v-if="state === 0" ref="code" @code_tell="reset_tell"></Code>
        </div>
        <!-- 手机号登录 -->
        <div class="tell_list" v-else-if="state === 1">
          <div class="tell">
            <el-form-item prop="tell">
              <el-input
                placeholder="请输入您的手机号"
                v-model="ruleForm.tell"
                clearable
              >
                <template slot="prepend">+86</template>
              </el-input>
            </el-form-item>
          </div>
          <div class="slider">
            <slider @verify="verify" ref="slider_init"></slider>
          </div>
          <div
            class="code_img"
            :class="verifys && ruleForm.tell.length === 11 ? '' : 'verify'"
          >
            <el-form-item prop="code">
              <el-input
                placeholder="请输入手机验证码"
                v-model="ruleForm.code"
                clearable
              >
                <el-button
                  slot="append"
                  v-if="!time.count && ruleForm.tell.length === 11"
                  @click="get_code(1)"
                  >获取验证码</el-button
                >
                <el-button slot="append" v-else
                  >{{ time.count }}获取验证码</el-button
                >
              </el-input>
            </el-form-item>
          </div>
          <div>
            <span class="login-btn margin-top" @click="submitForm()">
              登录
            </span>
          </div>
          <div class="text-left margin-top-sm">
            <a
              class="text-xs"
              @click="
                () => {
                  reset();
                  MtaH5.clickStat('login', { register: 'true' });
                }
              "
              >还没有账号? 立即注册
            </a>
          </div>
        </div>

        <!-- 绑定手机 -->
        <div class="register_list tell_list" v-else-if="state === 2">
          <div class="tell">
            <el-form-item prop="tell">
              <el-input
                placeholder="请输入您的手机号"
                v-model="ruleForm.tell"
                clearable
              >
                <template slot="prepend">+86</template>
              </el-input>
            </el-form-item>
          </div>
          <div class="slider">您的微信扫码已经授权成功~</div>
          <div
            class="code_img"
            :class="ruleForm.tell.length === 11 ? '' : 'verify'"
          >
            <el-form-item prop="code">
              <el-input
                placeholder="请输入手机验证码"
                v-model="ruleForm.code"
                clearable
              >
                <el-button
                  slot="append"
                  v-if="ruleForm.tell.length === 11 && time.show"
                  @click="get_code(2)"
                  >获取验证码</el-button
                >
                <el-button slot="append" v-else
                  >{{ time.count }}获取验证码</el-button
                >
              </el-input>
            </el-form-item>
          </div>
          <div class="hint">
            <el-checkbox-group v-model="type">
              <el-checkbox
                label="我已阅读并同意"
                style="color: #000"
              ></el-checkbox>
              <a
                href="https://www.shihaishibiao.com/docs/contract.html"
                target="_blank"
              >
                <span style="color: #f2c811; font-size: 14px"
                  >《士海食标服务协议》</span
                >
              </a>
            </el-checkbox-group>
          </div>
          <div class="set_btn">
            <a class="login-btn" plain @click="register_btn">同意条款并注册</a>
          </div>
        </div>
        <!-- 注册 -->
        <div class="register_list tell_list" v-else-if="state === 3">
          <div class="tell">
            <el-form-item prop="tell">
              <el-input
                placeholder="请输入您的手机号"
                v-model="ruleForm.tell"
                clearable
              >
                <template slot="prepend">+86</template>
              </el-input>
            </el-form-item>
          </div>
          <div class="slider">
            <register></register>
          </div>
          <div
            class="code_img"
            :class="ruleForm.tell.length === 11 ? '' : 'verify'"
          >
            <el-form-item prop="code">
              <el-input
                placeholder="请输入手机验证码"
                v-model="ruleForm.code"
                clearable
              >
                <el-button
                  slot="append"
                  v-if="ruleForm.tell.length === 11 && time.show"
                  @click="get_code(2)"
                  >获取验证码</el-button
                >
                <el-button slot="append" v-else
                  >{{ time.count }}获取验证码</el-button
                >
              </el-input>
            </el-form-item>
          </div>
          <div class="hint">
            <el-checkbox-group v-model="type">
              <el-checkbox
                label="我已阅读并同意"
                style="color: #000"
              ></el-checkbox>
              <a
                href="https://www.shihaishibiao.com/docs/contract.html?ctxonly=1"
                target="_blank"
              >
                <span style="color: #f2c811; font-size: 14px"
                  >《士海食标服务协议》</span
                >
              </a>
            </el-checkbox-group>
          </div>
          <div class="set_btn">
            <a class="login-btn" plain @click="register_btn">同意条款并注册 </a>
          </div>
        </div>
        <div v-else>加载中...</div>
      </el-form>
    </div>
    <div
      class="cut"
      @click="cut"
      :title="state != 0 ? '扫码登录' : '手机号登录'"
    >
      <span class="iconfont" :class="cut_state"></span>
    </div>
    <div
      class="off_btn"
      @click="
        () => {
          $emit('off_btn', false);
          MtaH5.clickStat('login', { close: 'true' });
        }
      "
    >
      <i class="el-icon-close"></i>
    </div>
  </div>
</template>
<script>
import { Slider, Register, Code } from "@/components/login";
import { isvalidatemobile } from "@/assets/js/validate";
var validPhone = (rule, value, callback) => {
  if (isvalidatemobile(value)[0]) {
    callback(new Error(isvalidatemobile(value)[1]));
  } else {
    callback();
  }
};
export default {
  props: ["login_state"],
  components: {
    Slider,
    Register,
    Code,
  },
  data() {
    return {
      title: "扫码登录",
      state: "",
      cut_state: "icondenglu-shoujihao",
      verifys: false,
      type: true,
      ruleForm: {
        tell: "",
        code: "",
      },
      rules: {
        tell: [
          { required: true, trigger: "blur", validator: validPhone }, //这里需要用到全局变量
        ],
      },
      time: {
        show: true,
        timer: null,
        count: "",
      },
    };
  },
  mounted() {
    if (this.login_state === -1) {
      this.reset();
    }
    if (this.login_state === 0) {
      this.state = 0;
    }
  },
  methods: {
    cut() {
      this.verifys = false;
      if (this.state === 1) {
        this.cut_state = "icondenglu-shoujihao";
        this.state = 0;
        this.title = "扫码登录";
        this.MtaH5.clickStat("login", { codelogin: "true" });
      } else if (this.state === 0) {
        this.state = 1;
        this.title = "手机号登录";
        this.cut_state = "icondenglu-saoma";
        this.MtaH5.clickStat("login", { telllogin: "true" });
      }
    },
    verify(e) {
      this.verifys = e;
    },
    get_code(e) {
      let that = this;
      if (that.state === 1) {
        if (!this.verifys) {
          return false;
        }
      }
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          let info = {
            mobile: this.ruleForm.tell,
          };
          if (e === 1) {
            this.$api.mobilecode_login(info).then((res) => {
              if (res.errcode === 0) {
                this.time.count = 60;
                this.$message.success("短信发送成功");
                this.time.show = false;
                this.time.timer = setInterval(() => {
                  if (this.time.count > 0) {
                    this.time.count--;
                  } else {
                    this.time.show = true;
                    clearInterval(this.time.timer);
                    this.time.timer = null;
                    this.time.count = "";
                  }
                }, 1000);
              }
            });
          } else if (e === 2) {
            // 注册
            this.$api.mobilecode(info).then((res) => {
              if (res.errcode === 0) {
                this.$message.success("短信发送成功");
                this.time.count = 60;
                this.time.show = false;
                this.time.timer = setInterval(() => {
                  if (this.time.count > 0) {
                    this.time.count--;
                  } else {
                    this.time.show = true;
                    clearInterval(this.time.timer);
                    this.time.timer = null;
                    this.time.count = "";
                  }
                }, 1000);
              }
            });
          }
        }
      });
    },
    register_btn() {
      let that = this;
      if (!this.type)
        return this.$message.warning("请先勾选士海食标服务协议后提交");
      // 用户申请注册
      this.$refs["ruleForm"].validate(async (valid) => {
        if (valid && this.ruleForm.code) {
          let info = {
            mobile: this.ruleForm.tell,
            mobileCode: this.ruleForm.code,
            sharingCode: localStorage.getItem("sCode") || undefined,
          };
          const res = await this.$api.users_post(info);
          if (res.errcode === 0) {
            that.$message.success("恭喜您，注册成功，正在登录...");
            that.$router.push("/index");
            const { userid } = res.data;
            //百度事件统计
            _hmt.push(["_trackEvent", "user", "reg", "oid", userid]);
            // 抖音事件统计
            //   meteor.track("view", { convert_id: "1688650980748291" });
          }
        } else {
          this.$message.warning("请您填选正确信息后再提交~");
          return false;
        }
      });
    },
    reset() {
      this.title = "欢迎新用户注册";
      this.state = 3;
      this.cut_state = "";
    },
    reset_tell() {
      this.title = "欢迎新用户注册";
      this.state = 2;
      this.cut_state = "";
    },
    submitForm() {
      // 登录
      let that = this;
      this.$refs["ruleForm"].validate((valid) => {
        if (valid && this.verifys) {
          let info = {
            mobile: this.ruleForm.tell,
            mobileCode: this.ruleForm.code,
          };
          that.$api.mobile(info).then((res) => {
            if (res.errcode === 0) {
              that.$router.push({ path: "/index" });
            }
          });
        } else {
          if (!this.verifys) {
            this.$message.error("您输入的校验码错误，请重试!");
          }
          // 使滑块恢复初始化状态
          this.$refs.slider_init.init();
          return false;
        }
      });
    },
  },
  destroyed() {
    clearInterval(this.time.timer);
    this.time.timer = null;
  },
};
</script>

<style lang="scss" >
.login_wrap {
  text-align: center;
  display: inline-block;
  background: #ffffff;
  width: 432px;
  height: 506px;
  border-radius: 6px;
  // overflow: hidden;
  box-shadow: 0 2px 6px #f2c811;
  -webkit-animation: fadeleftIn 0.4s;
  animation: fadeleftIn 0.4s;
  -webkit-animation-name: popIn;
  animation-name: popIn;
  margin: auto;
  .login-btn {
    width: 100%;
    border-radius: 50px;
    background: #f2c811;
    color: #333;
    display: inline-block;
    font-size: 14px;
    line-height: 40px;
    cursor: pointer;
  }
  @-webkit-keyframes popIn {
    0% {
      -webkit-transform: scale3d(0, 0, 0);
      transform: scale3d(0.5, 0.5, 0.5);
      opacity: 0;
    }
    50% {
      -webkit-animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
      animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
    }
    100% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
      -webkit-animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
      animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
      opacity: 1;
    }
  }
  @keyframes popIn {
    0% {
      -webkit-transform: scale3d(0, 0, 0);
      transform: scale3d(0.5, 0.5, 0.5);
      opacity: 0;
    }
    50% {
      -webkit-animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
      animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
    }
    100% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
      -webkit-animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
      animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
      opacity: 1;
    }
  }
  .off_btn {
    position: absolute;
    right: -50px;
    top: -50px;
    color: #f3dfdfe7;
    font-size: 35px;
    display: inline-block;
  }
  .cut::after {
    display: block;
    content: "";
    border-width: 67px;
    border-style: solid;
    transform: rotate(45deg);
    border-color: #ffffff transparent transparent transparent;
    z-index: 9;
    position: absolute;
    right: 16px;
    bottom: -67px;
  }
  .cut {
    right: 0;
    top: 0;
    padding-right: 20px;
    padding-top: 5px;
    padding-left: 21px;
    padding-bottom: 40px;
    display: inline-block;
    position: absolute;
    background: rgb(242, 200, 17);
    overflow: hidden;
    span {
      font-size: 30px;
    }
  }
}
.login_pop {
  position: absolute;
  width: 100%;
  height: 100%;

  .tell_list {
    padding: 0 48px;

    .slider {
      margin: 28px 0;
    }
  }
  .register_list {
    .hint {
      text-align: left;
      line-height: 53px;
      .el-checkbox__label {
        color: #000;
      }
      .el-checkbox {
        margin: 0;
      }
    }
  }
  .title {
    font-size: 18px;
    line-height: 120px;
    font-weight: bold;
  }
}
@media only screen and (max-width: 767px) {
  .login_wrap {
    width: 85%;
    height: 400px;
    .title {
      line-height: 90px;
    }
    .off_btn {
      right: 0;
    }
    .set_btn {
      margin: 10px 0;
    }
    .tell_list {
      padding: 0 15px;
    }
  }
}
</style>
