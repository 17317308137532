<template>
  <div class="app_login">
    <el-container>
      <Header @btn="login_btn" @register="register_btn" />
      <el-main>
        <Page1
          @login="register_btn"
          @pop="
            () => {
              dialogVisible = true;
              MtaH5.clickStat('login', { topdemo: 'true' });
            }
          "
        />
        <Page2 />
        <Page3 />
        <Page4 @btn="login_btn" />
        <div class="page_img5">
          <div class="page_info">
            <img
              src="https://img.shihaishibiao.com/sh/page5.jpg?imageslim"
              alt="团队从事食品行业超过十年"
            />
            <div class="roll_list">
              <div class="page_title">
                <h3 class="title">实力强大的团队，就是影响力</h3>
                <div class="title_info">
                  团队从事食品行业超过十年
                  <br />审核食品标签数万个，基本覆盖所有食品类别
                  <br />团队成员均为食品专业技术人员或具备多年食品行业从业经验人员
                  <br />国内第一家独立开发《食品标签经典案例分析及职业打假应对》培训课程
                  <br />已累计开展“食品标签专题培训班”超过50期
                </div>
              </div>
              <div class="max_box hidden-xs-only">
                <div
                  class="roll_icon dib icon_right"
                  @click="
                    () => {
                      left();
                      MtaH5.clickStat('login', { team: 'true' });
                    }
                  "
                >
                  <span class="iconfont right dib iconright"></span>
                </div>
                <el-row :gutter="20">
                  <el-col :sm="6" v-for="idx in [0, 1, 2, 3]" :key="idx">
                    <el-carousel
                      :autoplay="false"
                      arrow="never"
                      :initial-index="idx"
                      indicator-position="none"
                      height="6.3rem"
                      :ref="'carousel' + idx"
                    >
                      <el-carousel-item
                        v-for="(item, index) in teamMembers"
                        :key="index"
                      >
                        <div class="roll_box">
                          <div class="photo">
                            <img
                              class="bg"
                              src="https://img.shihaishibiao.com/sh/page6.jpg?imageslim"
                              alt="食品标签人员背景"
                            />
                            <div class="photo_img">
                              <img
                                :src="item.avatar"
                                :alt="'士海食标 - ' + item.name"
                              />
                            </div>
                            <div class="photo_name">姓名:{{ item.name }}</div>
                          </div>
                          <div class="introduce">
                            <div class="box_title">
                              <span class="iconfont iconshanchanglingyu"></span>
                              <span class="text">擅长领域：</span>
                            </div>
                            <div class="info">{{ item.skill }}</div>
                          </div>
                          <div class="introduce intro">
                            <div class="box_title">
                              <span class="iconfont icongerenjianjie"></span>
                              <span class="text">个人介绍：</span>
                            </div>
                            <div class="info">{{ item.intro }}</div>
                          </div>
                        </div>
                      </el-carousel-item>
                    </el-carousel>
                  </el-col>
                </el-row>
                <div
                  class="roll_icon dib"
                  @click="
                    () => {
                      right();
                      MtaH5.clickStat('login', { team: 'true' });
                    }
                  "
                >
                  <div class="iconfont left dib iconright"></div>
                </div>
              </div>
              <div class="max_box hidden-sm-and-up">
                <div class="photo_wrap">
                  <div v-for="(item, index) in teamMembers" :key="index">
                    <div class="roll_box">
                      <div class="photo">
                        <img
                          class="bg"
                          src="https://img.shihaishibiao.com/sh/page6.jpg?imageslim"
                          alt="士海"
                        />
                        <div class="photo_img">
                          <img
                            :src="item.avatar"
                            :alt="'士海食标 - ' + item.name"
                          />
                        </div>
                        <div class="photo_name">姓名:{{ item.name }}</div>
                      </div>
                      <div class="introduce">
                        <div class="box_title">
                          <span class="iconfont iconshanchanglingyu"></span>
                          <span class="text">擅长领域：</span>
                        </div>
                        <div class="info">{{ item.skill }}</div>
                      </div>
                      <div class="introduce intro">
                        <div class="box_title">
                          <span class="iconfont icongerenjianjie"></span>
                          <span class="text">个人介绍：</span>
                        </div>
                        <div class="info">{{ item.intro }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Lines @btn="login_btn" />
        <Footer />
      </el-main>
    </el-container>
    <!-- 轮播 -->
    <el-dialog class="el_pop" :visible.sync="dialogVisible" width="100%" center>
      <el-button type="text" @click="dialogVisible = false" class="btn_close">
        <i class="el-icon-close"></i>
      </el-button>
      <el-carousel :interval="4000" type="card" :height="ban_height + 'px'">
        <el-carousel-item
          class="el-carousel__item"
          v-for="item in list"
          :key="item"
        >
          <el-row>
            <el-col :span="24">
              <img
                ref="ban_height"
                @load="img_load"
                class="carousel-image"
                :src="item"
                alt="士海食标二维码"
              />
            </el-col>
          </el-row>
        </el-carousel-item>
      </el-carousel>
    </el-dialog>
    <div class="pop_box" v-if="pop_login">
      <login-box
        class="pop_box"
        ref="login_box"
        @off_btn="pop_bg"
        :login_state="login"
      ></login-box>
      <div class="pop_bg"></div>
    </div>
    <!-- 悬浮框 -->
    <div class="float-btn">
      <el-popover placement="left" trigger="hover" width="160">
        <div class="text-center">
          <div class="img">
            <img style="width: 100%" src="./assets/code.jpg" alt="扫码咨询" />
          </div>
          <div>扫码咨询</div>
          <div class="fwb">客服热线：400-008-2003</div>
        </div>
        <div slot="reference">
          <div class="bg-white float-text text-center">
            <div>
              <i class="el-icon-phone icon" />
            </div>
            <div class="text">联系我们</div>
          </div>
        </div>
      </el-popover>
    </div>
  </div>
</template>
<script>
import LoginBox from "@/components/login/login_box";
import {
  Footer,
  Header,
  Page4,
  Page3,
  Page2,
  Page1,
  Lines,
} from "@/page/login/components";
import Cred from "./components/card";
import Bus from "@/utils/bus";
export default {
  components: {
    LoginBox,
    Header,
    Footer,
    Page4,
    Page3,
    Page2,
    Page1,
    Lines,
    Cred,
  },
  data() {
    return {
      pop_login: false,
      login: "",
      dialogVisible: false,
      list: [
        "https://img.shihaishibiao.com/demo/main.png",
        "https://img.shihaishibiao.com/demo/main2.png",
        "https://img.shihaishibiao.com/demo/auth.png",
        "https://img.shihaishibiao.com/demo/auth1.png",
        "https://img.shihaishibiao.com/demo/auth2.jpg",
        "https://img.shihaishibiao.com/demo/newlabel.png",
        "https://img.shihaishibiao.com/demo/pay.png",
        "https://img.shihaishibiao.com/demo/trades.png",
        "https://img.shihaishibiao.com/demo/account.png",
        "https://img.shihaishibiao.com/demo/report.png",
        "https://img.shihaishibiao.com/demo/charge.png",
      ],
      ban_height: "",
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    if (this.$store.getters.user) {
      // this.$router.push("/index");
    } else {
      console.log("prerender event");
      this.$nextTick(() => {
        document.dispatchEvent(new Event("render-trigger"));
      });
    }
    let status = parseInt(this.$route.query.status);
    if (status === 1) {
      this.login_btn();
    } else if (status === 2) {
      this.register_btn();
    }
  },
  computed: {
    teamMembers() {
      return this.$store.getters.systemInfo.teamMembers;
    },
    teamMembersCnt() {
      return this.$store.getters.systemInfo.teamMembers.length;
    },
  },
  methods: {
    img_load() {
      this.$nextTick(() => {
        this.ban_height = this.$refs.ban_height[0].height;
      });
    },
    left() {
      for (let i = 0; i < 4; i++) {
        this.$refs["carousel" + i][0].prev();
      }
      console.log("prev");
    },
    right() {
      for (let i = 0; i < 4; i++) {
        this.$refs["carousel" + i][0].next();
      }
      console.log("next");
    },
    login_btn(key) {
      if (key) {
        let obj = {};
        obj[key] = "true";
        this.MtaH5.clickStat("login", obj);
      }
      // 登录按钮
      this.login = 0;
      this.pop_login = true;
      let mo = function (e) {
        e.preventDefault();
      };
    },
    register_btn() {
      // 注册
      this.login = -1;
      this.pop_login = true;
      let mo = function (e) {
        e.preventDefault();
      };
    },
    pop_bg() {
      this.pop_login = false;
      let mo = function (e) {
        e.preventDefault();
      };
      Bus.$emit("off_poll", false);
    },
  },
  watch: {
    dialogVisible(e) {
      if (e) {
        this.img_load();
        window.addEventListener("resize", () => {
          this.ban_height = this.$refs.ban_height[0].height;
          this.img_load();
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/css/login.scss";
 
.float-btn {
  position: fixed;
  right: 20px;
  bottom: 35%;
  margin-bottom: -64px;
  z-index: 1;
}
 

.float-text {
  text-decoration: none;
  width: 44px;
  height: 128px;
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.15);
  background-color: #fff;
  text-align: center;
  display: inline-block;
  cursor: pointer;
  .icon {
    color: #000;
    display: block;
    margin: 15px auto 10px;
    font-size: 25px;
    text-align: center;
    line-height: 22px;
  }
  .text {
    display: inline-block;
    width: 14px;
    font-size: 14px;
    color: #000;
    line-height: 17px;
    text-decoration: none;
  }
}
</style>
